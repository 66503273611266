<template>
  <div class="sckz-table-container">
    <div class="table-btns">
      <div class="left">
        <span @click="Visible = true" class="dimension-btn"><a-icon class="dimension-icon" type="radar-chart" />维度</span>
        <div class="dimension_list sort-checkbox-group">
          <a-checkbox @click="changeDimension(item)" v-for="item in allDimesion" :key="item.prop" :checked="query.groupByList.includes(item.key)">{{ item.name }}</a-checkbox>
        </div>
      </div>
      <m-link-button @click="handleCopy" :disabled="downLoading" iconPos="right">导出<ExportSvg slot="icon"/></m-link-button>
    </div>
    <div class="table-wrapper">
      <a-spin :spinning="isLoading">
        <el-table :data="tableData" style="width: 100%" @sort-change="handleSortChange" ref="table">
          <template v-for="(item, index) in currentColumns">
            <el-table-column
              :prop="item.prop"
              :key="item.key + tableKey"
              :label="item.name"
              :width="item.width"
              :fixed="index === 0"
            >
              <template slot-scope="{ row }">
                <div
                  v-if="item.prop === dimesionEnum.creativeUrl.prop"
                  @click="clickCreative(row)"
                  class="creative-box"
                >
                  <img v-if="row.type === 1" :src="row.creativeUrl" :alt="row.title" />
                  <video v-else :src="row.creativeUrl" :controls="false"></video>
                </div>
                <template v-else-if="item.prop === dimesionEnum.date.prop">
                  {{ row.date }}
                </template>
                <div class="prop-box app-name" style="width: 130px" v-else-if="item.prop === dimesionEnum.publisherName.prop">
                  <!-- <IosSvg v-if="+row.os === 0" class="icon" />
                  <AndroidSvg v-if="+row.os === 1" class="icon" /> -->
                  {{ row.publisherName }}
                </div>
                <div v-else-if="item.prop === dimesionEnum.placeName.prop" class="prop-box" style="width: 130px">
                  {{ row.placeName }}
                </div>
                <div v-else-if="item.prop === dimesionEnum.sourceName.prop" class="prop-box" style="width: 130px">
                  {{ row.sourceName }}
                </div>
                <div v-else-if="item.prop === dimesionEnum.platName.prop" class="prop-box" style="width: 130px">
                  {{ row.platName }}
                </div>
                <template v-else-if="item.prop === dimesionEnum.osName.prop">
                  {{ row.osName }}
                </template>
                <div v-else-if="item.prop === dimesionEnum.packageName.prop" class="prop-box" style="width: 130px">
                  {{ row.packageName }}
                </div>
                <div v-else-if="item.prop === dimesionEnum.productName.prop" class="prop-box" style="width: 130px">
                  {{ row.productName }}
                </div>
                <template v-else-if="item.prop === dimesionEnum.type.prop">
                  {{ row.type === 1 ? '图片' : '视频' }}
                </template>
                <div :title="row.bundleName" v-else-if="item.prop === dimesionEnum.trafficBundle.prop">
                  {{ row.trafficBundle }}
                </div>
              </template>
            </el-table-column>
          </template>
          <el-table-column sortable="custom" :sort-orders="['descending', 'ascending', null]" prop="impress" label="展示" min-width="80">
            <template slot-scope="{ row }"> {{ numFormat(row.impress, 3, '') }}</template>
          </el-table-column>
          <el-table-column sortable="custom" :sort-orders="['descending', 'ascending', null]" prop="click" label="点击" min-width="80">
            <template slot-scope="{ row }"> {{ numFormat(row.click, 3, '') }}</template>
          </el-table-column>
          <el-table-column sortable="custom" :sort-orders="['descending', 'ascending', null]" prop="clickRate" label="点击率" min-width="100">
            <template slot-scope="{ row }"> {{ row.clickRate }}% </template>
          </el-table-column>
          <el-table-column sortable="custom" :sort-orders="['descending', 'ascending', null]" prop="income" label="预估收益" min-width="120">
            <template slot-scope="{ row }"> {{ numFormat(row.income, 3, '') }}</template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            :sort-orders="['descending', 'ascending', null]"
            prop="estimatedRevenueEcpm"
            label="媒体成交eCPM"
            min-width="120"
          >
            <template slot-scope="{ row }"> {{ row.estimatedRevenueEcpm }}</template>
          </el-table-column>
        </el-table>
        <m-empty v-if="tableData.length === 0" style="height: 341px" imgHeight="176px">
          <template #description> 暂时没有任何数据 </template>
        </m-empty>
        <a-pagination
          class="pagination"
          v-if="tableData.length > 1"
          show-size-changer
          :current="query.page"
          :total="total"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="query.limit"
        />
      </a-spin>
    </div>
    <!-- <CustDimension
      v-if="dimesionVisible"
      :visible="dimesionVisible"
      :allDimesion="allDimesion"
      :groupByList="query.groupByList"
      @modalCancel="dimesionVisible = false"
      @changeCustDimension="changeCustDimension"
      :submitBack="dimesionBack"
    /> -->
    <CreativeDetailModal
      v-if="detailVisible"
      :visible="detailVisible"
      :creativeInfo="creativeInfo"
      @modalCancel="detailVisible = false"
    />
  </div>
</template>

<script>
import CustDimension from '@/views/reportdatas/SynthReport/SynthTable/CustDimension'
import CreativeDetailModal from '../components/creativeDetailModal'
import { dimesionList, dimesionEnum } from './dimesionList'
import { creativeCZReport } from '@/apiForManage/originLity'
import excel from '@/utils/excel'
import request from '@/utils/request'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import { numFormat } from '@/utils/dealNumber'
import ExportSvg from '@/assets/icons/export.svg?inline'
import mixFetchPool from '@/mixins/fetchPool'
import Sortable from 'sortablejs'
export default {
  name: 'SCKZTable',
  components: { CustDimension, CreativeDetailModal, IosSvg, AndroidSvg, ExportSvg },
  mixins: [mixFetchPool],
  data () {
    return {
      // 维度枚举
      dimesionEnum,
      // 是否显示素材弹窗
      detailVisible: false,
      // 素材详情数据
      creativeInfo: {},
      // 所有维度列表
      allDimesion: JSON.parse(JSON.stringify(dimesionList)),
      tableKey: '1',
      // 是否显示维度弹窗
      // dimesionVisible: false,
      // 查询参数
      query: {
        page: 1,
        limit: 10,
        groupByList: [dimesionEnum.date.value, dimesionEnum.creativeUrl.value],
        sortOrder: undefined,
        sortBy: undefined
      },
      // 数据总数
      total: 0,
      // 表单数据
      tableData: [],
      // 下载loading
      downLoading: false,
      // 表单loading
      isLoading: false,
      // 导出参数暂存对象
      tempQuery: {},
      // 默认维度
      currentColumns: [
        {
          key: dimesionEnum.date.value,
          name: dimesionEnum.date.name,
          checked: false,
          prop: dimesionEnum.date.prop,
          width: dimesionEnum.date.width
        },
        {
          key: dimesionEnum.creativeUrl.value,
          name: dimesionEnum.creativeUrl.name,
          checked: false,
          prop: dimesionEnum.creativeUrl.prop,
          width: dimesionEnum.creativeUrl.width
        }
      ]
    }
  },
  mounted () {
    this.initSortable()
  },
  methods: {
    // 拖拽回调处理
    handelDimesionSort (newIndex, oldIndex) {
      const oldItem = this.allDimesion.splice(oldIndex, 1)
      this.allDimesion.splice(newIndex, 0, oldItem[0])
      this.$nextTick(() => {
        this.initSortable()
        this.changeCustDimension()
      })
    },
    // 维度拖拽排序
    initSortable () {
      const el = document.querySelector('.sort-checkbox-group')
      /* eslint-disable no-new */
      new Sortable(el, {
        animation: 150,
        onEnd: (evt) => {
          this.handelDimesionSort(evt.newIndex, evt.oldIndex)
        }
      })
    },
    changeDimension (item) {
      this.$refs.table.clearSort()
      if (this.query.groupByList.includes(item.key)) {
        this.query.groupByList = this.query.groupByList.filter((i) => i !== item.key)
      } else {
        this.query.groupByList.push(item.key)
      }
      this.changeCustDimension()
      this.query.page = 1
      this.query.sortBy = undefined
      this.query.sortOrder = undefined
      this.getTableData()
    },
    numFormat,
    // 父表排序
    handleSortChange ({ column, prop, order }) {
      if (order === 'ascending') {
        this.query.sortOrder = 'asc'
      } else if (order === 'descending') {
        this.query.sortOrder = 'desc'
      } else {
        this.query.sortOrder = undefined
      }
      this.query.sortBy = this.query.sortOrder ? prop : undefined
      this.query.page = 1
      this.getTableData()
    },
    // 处理导出
    async handleCopy () {
      this.downLoading = true
      const baseCodeList = ['impress', 'click', 'clickRate', 'income', 'estimatedRevenueEcpm']
      const baseNameList = ['展示', '点击', '点击率', '预估收益', '媒体成交eCPM']
      const query = { ...this.tempQuery }
      const dimesionCodeList = []
      const dimesionNameList = []
      this.currentColumns.forEach((item) => {
        dimesionCodeList.push(item.prop)
        dimesionNameList.push(item.name)
      })
      query.codeList = [...dimesionCodeList, ...baseCodeList]
      query.nameList = [...dimesionNameList, ...baseNameList]
      try {
        const res = await this.exportToExcel(query)
        await excel(res, `素材快照.xlsx`)
        this.downLoading = false
      } catch (e) {
        console.log(e, '导出excel错误信息')
      } finally {
        this.downLoading = false
      }
    },
    // 获取到出报表数据
    exportToExcel (query) {
      return request.post('/creativeSS/downloadReport', query, {
        responseType: 'blob'
      })
    },
    // 素材详情点击
    clickCreative (row) {
      this.creativeInfo = JSON.parse(JSON.stringify(row))
      this.detailVisible = true
    },
    // 自定义维度确定
    changeCustDimension () {
      this.currentColumns = this.allDimesion.filter((item) => this.query.groupByList.includes(item.key))
      this.tableKey = new Date().getTime().toString()
    },
    /**
     * 供父级调用查询
     * @param {Object} query 查询条件
     */
    dealQuery (query) {
      this.query = {
        ...this.query,
        ...query,
        page: 1,
        sortOrder: undefined,
        sortBy: undefined
      }
      this.$nextTick(() => {
        this.$refs.table.clearSort()
      })
      this.getTableData()
    },
    // 修改page
    handleChangePage (page) {
      this.query.page = page
      this.getTableData()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getTableData()
    },
    // 获取数据
    async getTableData () {
      this.getCancelToken('sckz')
      this.isLoading = true
      // 获取表格数据
      const { data = {} } = await creativeCZReport(this.query, this.cancelSource.token)
      const { total = 0, items = [] } = data
      this.total = total
      this.tableData = items
      this.isLoading = false
      this.tempQuery = JSON.parse(JSON.stringify(this.query))
      this.removeCancelSource('sckz')
    }
  }
}
</script>
<style lang="less" scoped>
.sckz-table-container {
  border-radius: @mediumRadius;
  background-color: #fff;
  padding: @smallMargin;
  border: 1px solid @modelBorderColor;
  box-shadow: @modelShadow;
  .table-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .left {
      display: flex;
      height: 36px;
      line-height: 36px;
      .dimension-btn{
        color: #344563;
        margin-right: 20px;
      }
      .dimension-icon{
        color: @assisColor;
        border: none;
        margin-right: 6px;
      }
      .dimension_list {
        display: flex;
        height: 100%;
        position: relative;
        padding-left: 20px;
        &::before{
          content: '';
          position: absolute;
          left: 0px;
          width: 1px;
          height: 22px;
          background-color: #ccc;
          transform: translateY(30%);
        }
      }
    }
  }
  .table-wrapper {
      .icon {
        width: 14px;
        height: 14px;
      }
    // }
    .creative-box {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 100px;
      width: 200px;
      background-color: #f5f6fa;
      border-radius: 3px;
      cursor: pointer;
      img,
      video {
        cursor: pointer;
        max-width: 200px;
        max-height: 100px;
      }
    }
    .prop-box {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .pagination {
      padding-top: @smallMargin;
    }
  }
}
</style>
